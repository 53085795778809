import logo from './logo.svg';
import './App.css';
import {AppBar, Box, Container, Grid, IconButton, Link, ThemeProvider, Toolbar, Typography} from "@mui/material";
import {theme} from "./theme";
import Logo from "./components/logo";
import styled from "@emotion/styled";
import {NavLink, BrowserRouter} from "react-router-dom";
import {LinkedIn} from "@mui/icons-material";



function App() {
    
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Container maxWidth={"lg"} sx={{
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center'
                }}>
                    <Box alignItems={"center"} justifyContent={"center"}>
                        <Logo color={'white'} fill={'#666666FF'} width={400} height={400} elevation={10} />
                        <Typography variant={"h2"} textAlign={"center"}>
                            qodlab
                            <IconButton component={Link} href={"https://www.linkedin.com/in/henry-de-la-martiniere/"}><LinkedIn fontSize={"large"} /></IconButton>
                        </Typography>
                    </Box>
                </Container>
            </BrowserRouter>
        </ThemeProvider>
    );
}

function NavButton({label, link}) {
    return <Link
        component={NavLink}
        to={`/fr/${link}`}
        sx={{
            mr: 2,
            textDecoration: 'none'
        }}
    >
        {label}
    </Link>
}

export default App;
